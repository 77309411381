<template>
    <div id="comments">
        <h2><SvgPathIcon name="contact-5" />Commentaires sur l'installation de {{ proprio }}</h2>        
        <p v-on:click="getComments('last')" v-if="!isEnded" class="lastComm secretLoader ">CHARGER LES DERNIERS COMMENTAIRES</p>
        <div v-for="comments in json" v-bind:key=comments.position class="commentBlock">
          <div class="graybg" :id="'comment_' + comments.ID">
            <span class="auteur" @click="displayInfo(comments.auteur, comments.ID)">
              <span v-if="comments.admin" class="admin">
                {{ comments.auteur }}
              </span>
              <span v-else>
                {{ comments.auteur }}
              </span>
              <ProprioDesc class="shadow infoMembre" v-if="comments.auteur === clickedAuteur && clickedId === comments.ID" v-bind:membre="true" :proprio="comments.auteur.replace(/ /g,'___')" @kill="kill"/>                      
            </span>
            <span class="heure">{{ comments.heure}}</span>
            <span class="position"><a :href="'#' + comments.position">#{{ comments.position }}</a></span><br/>
            <span class="action" v-if="login && comments.moderer === 'non'"><a @click="doIt" :id="'rep_' + comments.ID" href="#">Répondre</a></span>
            <span class="action" v-if="displayEdit(comments.auteur, login, admin)" :id="'pos_' + comments.position"><a @click="doIt" :id="'edit_' + comments.ID" href="#">Editer</a></span>
            <span class="action" v-if="admin"><a @click="doIt" :id="'mod_' + comments.ID" href="#">Modérer</a></span>
            <span class="action interaction">
              <span v-if="selectedComment === comments.ID && selectedAction === 'moderation'">
                <span color="red" v-if="retourAction ==='ok'">Pris en compte !</span>
                <span color="red" v-else-if="retourAction === 'ko'">Erreur !</span>
                <span v-else><button @click="moderate" class=" shadow button">Valider</button></span>
              </span>
              <span v-else-if="selectedComment === comments.ID && selectedAction === 'edition'" class="red">Mode Edition</span>
            </span>
          </div>
          <EditComments v-if="selectedComment === comments.ID && selectedAction === 'edition'" :comment="comments.ID" @edit="edit" @cancel="cancel"/>
          <div v-else v-html="comments.message" class="message whitebg">{{ comments.message }}</div>
        </div>            
      <p v-on:click="getComments('next')" v-if="!isEnded" class="nextComm secretLoader sticky">CHARGER LES COMMENTAIRES SUIVANTS</p>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import EditComments from '@/components/Presentation/EditComments.vue'
import ProprioDesc from '@/components/Presentation/Proprio.vue'
import SvgPathIcon from "@/components/SvgPathIcon.vue"
export default {
  name: 'comments',
  components: {
    EditComments,
    ProprioDesc,
    SvgPathIcon   
  },
  props: {
    proprio: String,
    forcecomments: String
  },
  data() {         
    return {
      iconNames: [
        "contact-5"
      ],
      json: null, 
      scrollPosition: null,
      windowHeight: 0,
      txt: '',
      actualSize: 0,
      scrolledToBottom: false,
      countLoad: 1,
      isEnded: false,
      login: localStorage.login,
      admin: localStorage.admin,
      selectedAction: null,
      selectedComment: null,
      apiUrl: '/backend/get/commentaire/',
      retourAction: null,
      clickedAuteur: null,
      clickedId: null,
      actualPosition: null
    }    
  },    
  methods: {    
    // COMMENTS ACTIONS
    doIt: function(e) {   
      var clickOn = e.target.id;
      var str = clickOn.split('_')
      this.selectedComment = str[1]
      if(clickOn.startsWith('mod_')) {
          //alert('Moderation')
        this.selectedAction = "moderation"
      }
      else if(clickOn.startsWith('edit_')) {
        this.actualPosition = event.target.parentElement.id.replace(/pos_/gi,'')
        this.selectedAction = "edition"        
      }
      else if(clickOn.startsWith('rep_')) {
        this.selectedAction = "repondre"
        //alert(this.selectedComment)
        this.isEnded = true
        this.$emit("responsetocomment", this.selectedComment)
      }
      e.preventDefault();
    },    
    moderate: function() {
      fetch(this.apiUrl + 'moderer',{
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({login: this.login, action: this.selectedAction, admin: this.admin, id : this.selectedComment})
      })
      .then(res => res.json())
      .then(res => {
        this.token = res.token
        if (res.error) {
          //this.errors.push(res.error);
          this.retourAction = "ko"
        } else {            
          this.retourAction = "ok"
          //OK
        }
        setTimeout(() => {
          this.retourAction = null
          this.selectedComment = null
        }, 1200)
      });
    },
    cancel: function() {
      this.selectedComment = null
      this.selectedAction = null
    },
    edit: function() {
      this.selectedAction = null  
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/commentaire/id/' + this.selectedComment)
      .then(response => {
      //this.json.concat(response.data.comments);
      for (var i = 0; i < this.json.length; i++) {
        if(this.json[i].ID === this.selectedComment) {
          //alert(this.json[i].message)
          //alert(response.data.comments[0].message)
          this.json[i].message = response.data.comments[0].message
        }
      }
      this.selectedComment = null
      })
    },
    displayInfo: function(auteur,IdCommentaire) {
      var parentId = event.target.parentElement.parentElement.id
      if(parentId.replace(/comment_/gi,'') === IdCommentaire) {
        this.clickedAuteur = auteur
        this.clickedId = IdCommentaire
      }
    },
    // PAGE COMPORTEMENT
    kill: function() {
      this.clickedAuteur = null
      this.clickedId = null
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;            
    },
    displayEdit: function(auteur,login,access) {
      if(access || auteur === login ) {
        return true
      } else {
        return false
      }
    },
    scroll () {
      window.onscroll = () => {
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
        if (bottomOfWindow) {
          this.getComments('next');                  
        }
      }
    },
    scrollToElement: function() {
      const el = this.$el.getElementsByClassName('sticky')[0]
      if (el) {
        el.scrollIntoView()
      }
    },
    getComments(pointer) {
      if( this.isEnded == false) {
        this.countLoad++;
        let url;
        if (pointer == 'next' && this.forcecomments != "last" ) {
          url = this.$protocolServer + '://' + this.$urlServer + '/backend/get/commentaire/proprio/' + this.proprio + '/' + this.countLoad +'/10';
        } else {
          url = this.$protocolServer + '://' + this.$urlServer + '/backend/get/commentaire/proprio/' + this.proprio + '/derniers/10';
        }
        axios.get(url)        
        .then(response => {
          if(response.data.comments != "" && pointer != 'last') {
            this.json = this.json.concat(response.data.comments);
            this.scrolledToBottom = false;
          } else {        
            if( pointer == 'last') {
              this.json = response.data.comments;
            }
            this.isEnded = true;
            this.scrolledToBottom = true;
          }
        })
      }
    }    
  },  
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }  
  },
  mounted() {   
    if(this.forcecomments != "last") {
      this.scroll()
      window.addEventListener('scroll', this.updateScroll)
      this.actualSize = (window.innerHeight+34)
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.windowHeight = window.innerHeight;
          this.actualSize = (window.innerHeight+34)
        })
      })  
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/commentaire/proprio/' + this.proprio + '/1/10')
      .then(response => {
        this.json = response.data.comments;
        if (this.json.length < 10 ) {
          this.isEnded = true;
        }
      })
    }
    else {
      this.getComments("last")
    }  
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.updateScroll)    
  }
}
</script>

<style scoped>
h2 {
  font-size: 1.1rem;
  font-weight: bold;  
}

#comments {
  text-align:left;
  margin:0 10px;
}

.auteur {
  font-weight: bold;
  padding-right:15px;
  padding-left:5px;
  cursor: pointer;
}

.action {
  font-weight:normal;
  padding-left:5px;
  padding-right:5px;
}

.heure {
  font-size: 0.9rem;
  padding-right:15px;
}

.secretLoader{
  background-color:#007bff;
  color:#eee;
  margin:5px 0;
  text-align:center;
  font-weight: bold;
  cursor:pointer;
}

.message {
  padding-left:5px;
  border-left:1px solid #777;
}

.commentBlock {
  margin-bottom:8px;
}

.admin {
  color:#007bff;
}

a:link {
  text-decoration: none;
}

.button {
  background-color: #007bff;
  border: none;
  color: #eee;
  padding: 1px 4px;  
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.red{
  color:red;
  font-weight:bold;
}

.infoMembre {    
  background-color:#fff;
  color: #111;
  border-radius: 3px;
  border:1px solid #111;
  padding:2px; 
  position:absolute;
  z-index: 1;  
  width:230px;
}

</style>
