<template>
  <div v-if="notHidden === false">
    <p>
      Non disponible pour le moment !
    </p>
  </div>
  <div v-else class="commentaires container-fluid min-vh-100">
    <vue-headful
      :title="'Install-HC - Commentaires à propos de L\'Installation Home-Cinema de ' + cleanProprio"
      :description="'Echanges constructifs à propos de l\'Installation Home-Cinema de ' + cleanProprio"
    />
    <checkDisplayed :proprio="proprio" @isDisplayed="isDisplayed"/>
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC @notlogged="notlogged"/>
        </div> 
        <Menu :key="componentKey"/>
      </nav>
    </div>
    <div class="picture">
      <Vignette :proprio="this.proprio"/>
        <p class="sections">
          <router-link :to="{path: '../installation/' + this.proprio }">
          Se rendre directement aux photos
          </router-link>
          <router-link :to="{path: '../presentation/' + this.proprio }">
          Consulter la présentation de l'Installation
          </router-link>
          <Favori :proprio="this.proprio"/>
        </p>
      <div class="debug">
        <h1>{{ actualSize }}px ! {{ txt }}</h1>        
      </div>      
      <Comments :key="forcecomments" :proprio="this.proprio" :forcecomments="forcecomments" @responsetocomment="responsetocomment"/>
      <PostComments :responseWanted="responseWanted" :key="responseWanted" :proprio="this.proprio" @last="last"/>      
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import Favori from '@/components/Favori.vue'
import Comments from '@/components/Presentation/Comments.vue'
import PostComments from '@/components/Presentation/PostComments.vue'
import Vignette from '@/components/Presentation/Vignette.vue'
import InstallHC from '@/components/InstallHC.vue'
export default { 
  name: 'commentaires',
  components: {
    Menu,
    Favori,
    Comments,
    Vignette,
    InstallHC,
    PostComments   
  },
  data() {         
    return {
      json: null, 
      windowHeight: 0,
      txt: '',
      actualSize: 0,
      target: '',
      forcecomments: 'current',
      responseWanted: null,
      componentKey: 0,
      notHidden: true
    }    
  },
  props: {
    proprio: {
      type: String,
      default: null
    }    
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },
  mounted() {
    this.actualSize = window.innerHeight    
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight;
        this.actualSize = (window.innerHeight-117)
      });
    })   
  },
  methods: {
    isDisplayed: function(e) {
      this.notHidden = e
    },
    last: function() {      
      if( this.forcecomments === "last" ) {
        // TO FORCE REFRESH !        
        this.forcecomments = null
        setTimeout(() => {
          this.forcecomments = null
        }, 100)
        
      } else {
        this.forcecomments = "last"      
      }
    },
    responsetocomment: function(value) {
      this.forcecomments = "last"
      this.responseWanted = value      
    },
    forceRerender: function() {
      this.componentKey += 1
    },
    notlogged: function() {
      this.forceRerender()
    }
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  }
  }

</script>

<style scoped>
.commentaires {
  margin:0 auto;  
  width:100%;    
  padding:0;
}

.debug {
  display: none;
}

.picture {
  width:100%;
  overflow:hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

h1 {
  padding: 0 55px;
  font-size: 1.05em;
  font-weight: bold;
  margin-top:8px;
}

a {
  font-weight: bold;
  font-size:1.1rem;
  display:inline-block;
  padding:0 30px;
}

a:link {
  text-decoration: none;
}
</style>