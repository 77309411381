<template>
    <div id="editcomments">
      <div v-for="comments in json" v-bind:key=comments.ID>
        <textarea id="newComment" rows="5" v-model="comments.message" :disabled="comments.moderer == 'oui'"/>
        <button @click="postNewComment" class="button" :disabled="comments.moderer == 'oui'">Sauvegarder</button><button class="button" @click="cancel">Annuler</button>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default {
  name: 'editcomments',  
  props: {
    comment: String
  },
  data() {         
    return {
      json: null
    }    
  },    
  methods: {    
    cancel: function() {
      setTimeout(() => {
        this.$emit("cancel")
      }, 200)
    },
    postNewComment: function() {
      var apiUrl = "/backend/get/commentaire/editer"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({newComment: document.getElementById("newComment").value,id: this.comment})
        })
        .then(res => res.json())
        .then(res => {
          if(res.post != "ok") {
            this.error = true            
            this.isPosted = false
          }
          else if(res.post === "ok") {
            this.isPosted = true
            this.error = false
            setTimeout(() => {
            this.$emit("edit")
            }, 200)            
          }       
        });
    }
  },
  
  watch: {
  },
  mounted() {   
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/commentaire/id/' + this.comment + '/raw')
    .then(response => {
      this.json = response.data.comments
    })  
  },
  beforeDestroy () {
  }
}
</script>

<style scoped>
textarea {
  width:100%;  
}


.button {
  background-color: #007bff;
  border: none;
  color: #eee;
  padding: 1px 4px;  
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  width:49%;
  margin:0 1px;
}

#editcomments {
  text-align:center;
}
</style>
