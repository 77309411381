<template>
  <div id="favori">    
    <a @click="isFav($event)" v-if="isShown" href="#">
      <span v-if="result != 'notPresent'">Retirez de mes favoris</span>
      <span v-else>Ajoutez à mes favoris</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios'
export default {  
  components: {
  },
  props: {
    proprio: String
  },
  data() {
    return {     
      result: null,
      errors: []
    }
  },  
  computed: {
    isShown: function () {
      if(localStorage.login) return true
      else return false
    },
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }  
  },
  methods: {
    isFav(e) {
      //alert(this.cleanProprio + ' is ' + this.result)
      var apiUrl = "/backend/get/form/manageFav/" + this.proprio
      this.errors = []
      var action = null
      if( this.result == 'notPresent') {
        //On ajoute
        action = 'add'
      } else {
        //On supprime
        action = 'del'
      }      
      fetch(apiUrl,{
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({login: this.login, proprio: this.proprio, action: action})
        })
        .then(res => res.json())
        .then(res => {
          this.token = res.token
          if (res.error) {
            this.errors.push(res.error);
          } else {                        
            setTimeout(() => {
              if (res.manageFav === 'delOk') {
                this.result = 'notPresent'
              } else {
                this.result = 'present'
              }
              //alert(action + ' is Done')              
            }, 100)
          }
        })
      e.preventDefault()  
    }
  },
  mounted() {    
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/form/checkFav/' + this.proprio)
    .then(response => {this.result = response.data.idFav;      
    })   
  }
}

</script>

<style scoped>

#favori {
  display:inline-block;
}

a {
  font-weight: bold;
  font-size:1.1rem;
  display:inline-block;
  padding:0 30px;
}

a:link {
  text-decoration: none;
}
</style>