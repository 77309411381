<template>
  <div id="postComments">
    <div class="postCommZone" @click="showInput">
      <div v-if="responseWanted !== null" class="outBulle"> 
        <div class="bulle">
          <quoteComment :comment="responseWanted"/>
        </div>
      </div>
      <span v-if="responseWanted === null">POSTER UN COMMENTAIRE</span>
      <span v-else>ECRIRE LA REPONSE</span>
      <div v-if="isDisplayed">
        <div v-if="isAuthenticated">
          <div v-if="error" class="red">
            Erreur lors de l'enregistrement du message !
          </div>
          <div v-if="!isPosted">
            <textarea rows="5" class="shadow"/><br/>
            <button @click="getComment" class="shadow button">Envoyer</button>
          </div>          
          <div v-else>
            Message posté avec succès !
          </div>
        </div>
        <div v-else>
          Vous n'êtes pas authentifié ! Merci de vous <router-link to="/login">connecter</router-link>.
        </div>
      </div>
    </div>
    <div class="bottomPost" ></div>
  </div>
</template>

<script>
import axios from 'axios'
import quoteComment from './quoteComment.vue'
export default {
  name: 'PostComments',
  components: {
    quoteComment
  },
  data() {
    return {
      json: null,
      isAuthenticated: false,
      isDisplayed:false,
      error: false,
      isPosted: false
      
    }
  },
  props: {
    proprio: String,
    responseWanted: String
  },
  mounted() {
    if(this.responseWanted !== null) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
        this.scrollToElement()
      },100)
    }
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/form/validateAccount')
    .then(response => {this.json = response.data.account});
  },
  methods: {
    showInput: function() {
      this.isDisplayed = true
      this.checkAuthentication()
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
        this.scrollToElement()
      },100)
    },
    scrollToElement: function() {
      const el = this.$el.getElementsByClassName('bottomPost')[0]
      if (el) {
        el.scrollIntoView()
      }
    },
    checkAuthentication: function() {
      if(this.json.error) {
        this.isAuthenticated = false
      } else {
        this.isAuthenticated = true
      }    
    },
    getComment: function(event) {
      var zone =  event.target.parentNode
      for (var i = 0; i < zone.children.length; i++) {
        if(zone.children[i].tagName === 'TEXTAREA' && zone.children[i].value.trim() != '') {
          //alert(zone.children[i].value.trim())
          this.postComment(zone.children[i].value.trim())
        }
      }      
    },
    postComment: function(string) {
      var apiUrl = "/backend/get/commentaire/poster"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({comment: string,proprio: this.cleanProprio,citation: this.responseWanted})
        })
        .then(res => res.json())
        .then(res => {
          if(res.post != "ok") {
            this.error = true            
            this.isPosted = false
          }
          else if(res.post === "ok") {
            this.isPosted = true
            this.error = false
            setTimeout(() => {      
              window.scrollTo(0, document.body.scrollHeight)
              this.scrollToElement()            
            }, 200)
            setTimeout(() => {              
              this.$emit("last")              
            }, 100)
          }       
        });
    }
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  }
}
</script>

<style scoped>
#postComments{
  margin: 0 10px;
}

.postCommZone{
  background-color:#007bff;
  color:#eee;
  margin:5px 0;
  text-align:center;
  font-weight: bold;
  cursor:pointer;
}

textarea{
  width:95%;
}

a {
  color: #ccc;
}
a:hover
{
  color: #ccc;
  text-decoration:none;  
}

.button {
  color: #007bff;
  border: none;
  background-color: #eee;
  padding: 10px 32px;
  margin-bottom:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.red{
  color:red;
}

.bulle {
  width: 98%;
  text-align: left;
  box-shadow: -1px 2px 10px 1px rgba(44, 62, 80, 0.7);
  font-weight: normal;
  cursor:not-allowed;
  background:#eee; 
  color:#333; 
  padding:7px 15px; 
  border-radius:3px;
}

.bulle::after {
  content:""; 
  border-left:20px solid transparent;
  border-right:20px solid transparent;
  border-top: 20px solid #eee;
  position: absolute;
}

.outBulle {
  padding-top:5px;
  padding-left:10px;
  margin-bottom: 5px;
}
</style>