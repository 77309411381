<template>
    <p id="quoteComment">      
      <span v-for="comments in json" v-bind:key=comments.ID>
        <b>{{  comments.auteur }} a écrit :</b><br>
        <span class="text" v-html="comments.message"/>
      </span>  
    </p>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
//import quoteComments from '@/components/Presentation/quoteComment.vue'
export default {
  name: 'quotecomments',  
  props: {
    comment: String
  },
  data() {         
    return {
      json: null
    }    
  },    
  methods: {    
  },
  mounted() {       
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/commentaire/id/' + this.comment + '/quote')
    .then(response => {
      this.json = response.data.comments
    })  
  },
  beforeDestroy () {
  }
}
</script>

<style scoped>
.text {
  padding-left:5px
}
</style>
